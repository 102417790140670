import React from 'react'
import Service from '../../pages/Service'
import Testimonials from '../../pages/Testimonials'
import Slider from '../../pages/Slider'
import HomeContact from '../../pages/HomeContact'
import Partnyor from '../../pages/Partnyor'

const Home = () => {
  return (
    <>
    <Slider/>
    <Service/>
    <Testimonials/>
    <Partnyor/>
    <HomeContact/>
    </>
  )
}

export default Home
