import React from "react";
import username from "../assets/img/username.webp" ;
const Testimonials = () => {
  const data = [
    {
      id: 0,
      title: "В. Голованов",
      desc: "Отличные ребята, очень помогли в процессе покупки дома. Отправил своего друга в их офисы. Очень доволен. Спасибо",
    },
    {
      id: 1,
      title: "К. Шеховцева",
      desc: "Мы очень довольны услугами, и очень ценим то, как они сопровождали нас на показах и консультировали на протяжении всего процесса. Вежливы, терпеливы и внимательны. ",
    },
    {
        id: 2,
        title: "М. Годунова",
        desc: "Мы работали с My Real Estate при продаже нашей квартиры в Эмиратах. Это было супер. Отвечали на наши различные вопросы на протяжении всего процесса. Умудрились получить несколько предложений выше указанной цены.",
    }
  ];
  return (
    <>
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-65"
        data-bs-bg="assets/img/bg/23.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h1 className="section-title white-color">
                  ОТЗЫВЫ НАШИХ <br /> КЛИЕНТОВ
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-6-active slick-arrow-3">
            {
                data.map((item,i)=>(
                    <div className="col-lg-4" key={i}>
                    <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                      <div className="ltn__testimoni-info">
                        <div className="ltn__testimoni-author-ratting">
                          <div className="ltn__testimoni-info-inner">
                            <div className="ltn__testimoni-img">
                              <img src={username} alt="#" />
                            </div>
                            <div className="ltn__testimoni-name-designation">
                              <h5>{item.title}</h5>
                            </div>
                          </div>
                          <div className="ltn__testimoni-rating">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p style={{lineHeight:"normal"}}>
                        {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
