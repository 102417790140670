import React from 'react'
import { Helmet } from 'react-helmet'

const ContactP = () => {
  return (
   <>
       <Helmet>
     <title>Контакт</title>
    </Helmet>
   <div>
  <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " data-bs-bg="assets/img/bg/14.jpg">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="ltn__breadcrumb-inner">
            <h1 className="page-title">Связаться с нами</h1>
            <div className="ltn__breadcrumb-list">
              <ul>
                <li><a href="index.html"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Главная страница</a></li>
                <li>Контакт</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ltn__contact-address-area mb-90">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
            <div className="ltn__contact-address-icon">
            <i className="fa-regular fa-envelope-open" style={{color:"#00C2CB"}}></i>
            </div>
            <h3>Адрес электронной почты</h3>
            <p>contact@my-group.ae</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
            <div className="ltn__contact-address-icon" >
            <i className="fa-solid fa-phone-volume" style={{transform:"rotate(320deg)",color:"#00C2CB"}}></i>
            </div>
            <h3>Номер <br /> телефона</h3>
            <p>+994-51-207-09-02</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow ">
            <div className="ltn__contact-address-icon">
            <i className="fa-solid fa-location-dot" style={{color:"#00C2CB"}}></i>
            </div>
            <h3>Адрес</h3>
            <p style={{lineHeight:"normal"}}><span style={{fontWeight:"bold"}}>Dubai:</span> 16C Dubai Creek Tower Building, 5-1st Street, Riggat Al Buteen, Deira, Dubai, UAE</p>
            {/* <p><span style={{fontWeight:"bold"}}>Baku:</span> AF Business House, 7th Floor, Nizami Street 203B, Baku, Azerbaijan AZ1010</p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style={{marginBottom:"100px"}}>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.2536093115946!2d55.31162541079633!3d25.262052798821728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d999f60a5fd%3A0xbb7d208c82e7325e!2sCreek%20park!5e0!3m2!1str!2saz!4v1720426564477!5m2!1str!2saz" style={{width:"100%",height:"450px",border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>

   
   </>
  )
}

export default ContactP
