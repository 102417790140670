import React from "react";
import img1 from "../assets/img/service.png";
import img2 from "../assets/img/security.png";
import img3 from "../assets/img/price.png";
import Helmet from "react-helmet" ;
const About = () => {
  const data = [
    { id: 0, title: "Услуги по управлению портфелем", icon:<i className="flaticon-secure-shield"></i> },
    { id: 1, title: "Беспристрастные консультации", icon:<i className="flaticon-operator"></i> },
    { id: 2, title: "Профессиональный подход", icon:<i className="flaticon-house"></i> },
    { id: 3, title: "Безопасная и прозрачная сделка", img: img2 },
    { id: 4, title: "Выделенный менеджер по работе с клиентами",img:img3 },
    { id: 5, title: "Техническая",title1:"поддержка",img:img1 },
  ];

  return (
    <>
    <Helmet>
     <title>О нас</title>
    </Helmet>
      <div
        className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "
        data-bs-bg="assets/img/bg/14.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title">О нас</h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="index.html">
                        <span className="ltn__secondary-color">
                          <i className="fas fa-home"></i>
                        </span>{" "}
                        Главная страница
                      </a>
                    </li>
                    <li>О нас</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__about-us-area pt-120--- pb-90 mt--30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src="assets/img/others/13.png" alt="About Us Image" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src="assets/img/others/8.png"
                      alt="video popup bg image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  О нас
                  </h6>
                  <h1 className="section-title">
                    My Real Estate LLC<span>.</span>
                  </h1>
                  <p>
                    My Real Estate LLC предлагает вам лучший выбор и услуги по
                    покупке недвижимости в Дубае
                  </p>
                  <p>Почему MyReal Estate LLC: </p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li style={{ lineHeight: "normal" }}>
                    <i className="flaticon-home-2"></i>
                    Мы говорим на вашем языке
                  </li>
                  <li style={{ lineHeight: "normal" }}>
                    <i className="flaticon-mountain"></i>
                    Мы предлагаем объективную информацию
                  </li>
                  <li style={{ lineHeight: "normal" }}>
                    <i className="flaticon-heart"></i>
                    Мы предоставляем вам расширенный поиск недвижимости по ОАЭ
                  </li>
                  <li style={{ lineHeight: "normal" }}>
                    <i className="flaticon-secure"></i>
                    Мы предлагаем как строящиеся, так и готовые объекты
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__service-area section-bg-1 pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Наши сервисы
                </h6>
                <h1 className="section-title">Наши основные услуги:</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            {data.map((item) => (
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon" >
                    <div style={{fontSize:"100px"}}>{item.icon}</div>
                    {item.img && <img src={item.img} alt="not found" className="img-fluid"  />}
                    {/* <img width="100" height="100" src="https://img.icons8.com/ios/100/average-2.png" alt="average-2"/> */}
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="service-details.html">{item.title} <br /> {item.title1}</a>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-10">
            <h3 className="text-center">
              Одним из основных направлений нашей деятельности является сектор
              девелопмента недвижимости. Будь то дом или здание, MyReal Estate
              LLC может помочь!
            </h3>
            <p>
              Мы понимаем цели потенциального покупателя и быстро подбираем
              объекты с достойным уровнем комфорта и повышенной окупаемостью.
              Работаем со всеми крупными застройщиками и строительными
              компаниями в ОАЭ, включая Danube Properties, Dubai Properties
              Group, Emaar, Midas Real Estate, Sobha Limited, Mismak Properties
              и Meydan. MyReal Estate MMC является одним из самых рентабельных
              для клиентов агентов по недвижимости среди стран СНГ и изменил
              представление многих людей об агентах по недвижимости. Наши агенты
              получают комиссионные не от покупателей, которые покупают
              недвижимость через наше агентство, а через застройщика, что
              является идеальной ситуацией для наших покупателей. My Real Estate
              предлагает самые престижные квартиры и дома, доступные в настоящее
              время для продажи в Дубае. Мы предлагаем идеальные районы для
              вашей недвижимости, прекрасно продуманные места, дома на берегу
              моря, полностью меблированные и немеблированные объекты, как
              незавершенные, так и готовые объекты
            </p>
            <h6>Почему MY REAL ESTATE LLC?</h6>
            <p>
              Выбор агента по недвижимости при покупке строящейся недвижимости в
              Дубае может быть особенно выгодным по нескольким причинам: 1.Наши
              агенты по недвижимости имеют глубокое понимание рынка строящейся
              недвижимости, включая информацию о последних проектах,
              застройщиках и инвестиционных возможностях. Можем помочь вам в
              выборе правильного внепланового проекта, который соответствует
              вашим требованиям и инвестиционным целям. ​ 2. Доступ к
              эксклюзивным возможностям перед запуском: имеем доступ к
              эксклюзивным возможностям перед запуском, недоступным для широкой
              публики. Эти внеплановые свойства могут предложить значительную
              экономию и инвестиционный потенциал. ​ 3. Помощь в процессе
              покупки. Процесс покупки строящейся недвижимости может быть
              сложным, при этом необходимо учитывать множество юридических и
              финансовых аспектов. Наш опытный агент поможет вам
              сориентироваться в процессе и обеспечит вас всей информацией,
              необходимой для принятия обоснованных решений. ​ 4. Послепродажная
              поддержка: обеспечим постоянную поддержку после продажи, включая
              помощь в устранении зацепок и любых проблем, которые могут
              возникнуть в процессе передачи.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
