import React from 'react'
const Footer = () => {
    const year = new Date() ;
    const currenYear = year.getFullYear() ;
  return (
    <>
        <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" data-bs-bg="img/1.jpg--">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                        <div className="coll-to-info text-color-white">
                            <h1 style={{ color: 'var(--color)' }}>Ищете дом мечты?</h1>
                            <p>Мы поможем вам воплотить в жизнь вашу мечту о новом доме</p>
                        </div>
                        <div className="btn-wrapper">
                            <a className="btn btn-effect-3 btn-white" href="tel:+994512070902">Whatsapp <i className="fa-brands fa-whatsapp"></i>
                            
                            </a>
                        </div>
                        <div className="btn-wrapper">
                            <a className="btn btn-effect-3 btn-white" href="mailto:contact@my-group.ae">Cвязаться сейчас<i className="fa-regular fa-envelope ms-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="ltn__footer-area" >
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5 py-5" style={{background:"#171B2A"}}>
            <div className="container-fluid ltn__border-top-2">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="ltn__copyright-design clearfix">
                            <p className='text-center'>2019-{currenYear} © Copyright <font color="#00C6CE" style={{textDecoration:"underline",cursor:"pointer"}}><a href="">Myrealestate.az</a></font> My Real Estate MMC {currenYear}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
