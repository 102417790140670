import React from 'react'
import { NavLink } from 'react-router-dom'

const Slider = () => {
  return (
    <div>
           <div className="ltn__slider-area ltn__slider-3  section-bg-1">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
       
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
                <div className="ltn__slide-item-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <div className="slide-item-info">
                                    <div className="slide-item-info-inner ltn__slide-animation">
                                        <div className="slide-video mb-50 d-none">
                                            <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
                                                <i className="fa fa-play"></i>
                                            </a>
                                        </div>
                                        <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home"></i></span>My Real Estate</h6>
                                        <h1 className="slide-title animated ">Предоставляем вам расширенный <br /> поиск недвижимости <br /> по <a href="https://en.wikipedia.org/wiki/United_Arab_Emirates" target="_blank" style={{textDecoration:"underline"}}>ОАЭ</a></h1>
                                        <div className="slide-brief animated">
                                            <p>
                                            Поможем купить <a href="https://en.wikipedia.org/wiki/United_Arab_Emirates" target="_blank" style={{textDecoration:"underline"}}>недвижимость в Дубае</a>
                                            </p>
                                        </div>
                                        <div className="btn-wrapper animated">
                                            <a href="/about">
                                            <a className="theme-btn-1 btn btn-effect-1">О нас</a>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-item-img">
                                    <img src="assets/img/slider/21.png" alt="#"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
            <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3">
                <div className="ltn__slide-item-inner  text-right text-end">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <div className="slide-item-info">
                                    <div className="slide-item-info-inner ltn__slide-animation">
                                        <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home"></i></span>My Real Estate</h6>
                                        <h1 className="slide-title animated ">Предлагаем как строящиеся, так и готовые объекты</h1>
                                        <div className="slide-brief animated">
                                            <p>Понимаем цели потенциального покупателя и быстро подбираем объекты с достойным уровнем комфорта и повышенной окупаемостью.</p>
                                        </div>
                                        <div className="btn-wrapper animated">
                                            <a href="#services" className="theme-btn-1 btn btn-effect-1">Услуги</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-item-img slide-img-left">
                                    <img src="assets/img/slider/21.png" alt="#"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Slider
