import React, { useState } from 'react'

const HomeContact = () => {
  
  const [change,setChange] = useState({
    name:'',
    email:'',
    phone:'',
    message:''
  })

  const handleChange=(e)=>{
    const {name,value} = e.target ;
    setChange(prevState=>({
      ...prevState,
      [name]:value
    }))
  }

  const handleSubmit=(e)=>{
    e.preventDefault() ;
  }
  return (
    <div>
      <div className="ltn__contact-message-area mb-80 ">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="ltn__form-box contact-form-box box-shadow white-bg">
          <h4 className="title-2">СВЯЗАТЬСЯ С НАМИ</h4>
          <form id="contact-form" method="post" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-item input-item-name ltn__custom-icon">
                  <input type="text" name="name" placeholder="Enter your name" className='inputHome' onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-item input-item-email ltn__custom-icon">
                  <input type="email" name="email" placeholder="Enter email address"  className='inputHome' onChange={handleChange}/>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-item input-item-phone ltn__custom-icon">
                  <input type="text" name="phone" placeholder="Phone" className='inputHome' onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className="input-item input-item-textarea ltn__custom-icon">
              <textarea name="message" placeholder="Enter message" defaultValue={""}  className='inputHome' onChange={handleChange}/>
            </div>
            <div className="btn-wrapper mt-0">
              <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Связаться с нами</button>
            </div>
            <p className="form-messege mb-0 mt-20" />
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default HomeContact
