import React from "react";
import brand1 from "../assets/img/part1.webp";
import brand2 from "../assets/img/part2.webp";
import brand3 from "../assets/img/part3.webp";
import brand4 from "../assets/img/part4.webp";
import brand5 from "../assets/img/part5.png";
const Partnyor = () => {
  return (
    <>
      <div className="container">
        <h1 className="text-center mb-5" style={{textTransform:"uppercase"}}>Компании, с которыми мы работаем:</h1>
        <div className="row justify-content-center">
          {[brand1, brand2, brand3, brand4, brand5].map((item, index) => (
            <div
              key={index}
              className="col-6 col-sm-6 col-md-4 col-lg-2 d-flex align-items-center justify-content-center"
            >
              <img src={item} alt="" className="img-fluid brandimg" style={{width:"80%",marginBottom:"100px"}} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Partnyor;
