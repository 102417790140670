import React, { useState } from 'react'
import dubaiimg from "../assets/img/dubai3.webp"
import { useParams } from 'react-router-dom'
import { dubai_data } from '../data/dubai_data'
import slugify from 'slugify'
import { Helmet } from 'react-helmet'
const ProductDetails = () => {
    const {title} = useParams()
    const [data,setData] = useState(dubai_data)
    const findData = data.find((item)=>{
        return slugify(item.title).toLowerCase() == title
    })
    console.log(findData);
  return (
  <>
  <Helmet>
    <title>{findData && findData.title.toLowerCase()}</title>
    <meta name="description" content={findData && findData.title}></meta>
  </Helmet>
     <div>
  <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image mb-0" data-bs-bg="img/bg/14.jpg">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="ltn__breadcrumb-inner">
            <h1 className="page-title">{findData.title}</h1>
            <div className="ltn__breadcrumb-list">
              <ul>
                <li><a href="index.html"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Главная страница</a></li>
                <li style={{opacity:"0.5",fontWeight:"bold"}}>Страница сведений</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ltn__img-slider-area mb-90">
    <div className="container-fluid">
      <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
        <div className="col-lg-12">
          <div className="ltn__img-slide-item-4">
            <a href="assets/img/img-slide/31.jpg" data-rel="lightcase:myCollection">
              <img src="assets/img/img-slide/31.jpg" alt="Image" />
            </a>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="ltn__img-slide-item-4">
            <a href="assets/img/img-slide/32.jpg" data-rel="lightcase:myCollection">
              <img src="assets/img/img-slide/32.jpg" alt="Image" />
            </a>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="ltn__img-slide-item-4">
            <a href="assets/img/img-slide/33.jpg" data-rel="lightcase:myCollection">
              <img src="assets/img/img-slide/33.jpg" alt="Image" />
            </a>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="ltn__img-slide-item-4">
            <a href="assets/img/img-slide/34.jpg" data-rel="lightcase:myCollection">
              <img src="assets/img/img-slide/34.jpg" alt="Image" />
            </a>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="ltn__img-slide-item-4">
            <a href="assets/img/img-slide/35.jpg" data-rel="lightcase:myCollection">
              <img src="assets/img/img-slide/35.jpg" alt="Image" />
            </a>
          </div>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className="col-11 col-sm-10 col-md-10 col-lg-10">
        <h3 className="text-center my-3" dangerouslySetInnerHTML={{__html:findData.title1}}/>
        <h6 className="text-center my-3" style={{fontSize:"small",opacity:"0.5"}}>{findData.title2}</h6>
        <p dangerouslySetInnerHTML={{__html:findData.desc}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc1}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc2}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc3}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc4}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc5}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc6}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc7}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc8}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc9}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc10}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc11}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc12}}/>
        <p dangerouslySetInnerHTML={{__html :findData.desc13}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc14}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc15}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc16}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc17}} />
        <p dangerouslySetInnerHTML={{__html:findData.desc18}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc19}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc20}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc21}}/>
        <p dangerouslySetInnerHTML={{__html:findData.title3}}/>
        <p dangerouslySetInnerHTML={{__html:findData.desc22}} />
        <p dangerouslySetInnerHTML={{__html:findData.desc23}} />
        <p dangerouslySetInnerHTML={{__html:findData.desc24}} />
        </div>
      </div>
      <div className="row g-3">
        {
         findData.img && findData.img.length > 0 &&  findData.img.map((i)=>{
                return <div className="col-12 col-sm-4 col-md-4 col-lg-4"><img src={i} alt="" className="img-fluid h-100 w-100" /></div>
                
            })
        }
        {
          findData.img2 && (
            <div className="d-flex justify-content-center">
              <img src={findData.img2} alt="" className="img-fluid w-50" />
            </div>
          )
        }
        {
          findData.img3 && (
            <div className="d-flex justify-content-center">
              <img src={findData.img3} alt="" className="img-fluid w-50" />
            </div>
          )
        }
      </div>
      
    </div>
  </div>
</div>

   {
    findData.title1 == "Frequently Asked Questions about the Purchase of Real Estate in Dubai" && (
        <div className="ltn__faq-area mb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="ltn__faq-inner ltn__faq-inner-2">
                        <div id="accordion_2">
                           {
                            findData.faqtitle.map((item,i)=>(
                                 
                            <div className="card" key={i}>
                            <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target={`#faq-item-2-${i}`} aria-expanded="false" dangerouslySetInnerHTML={{__html:item.title}}/>
                     
                            <div id={`faq-item-2-${i}`} className="collapse" data-bs-parent="#accordion_2">
                                <div className="card-body">
                                    <p dangerouslySetInnerHTML={{__html:item.desc}}/>
                                </div>
                            </div>
                        </div>
                            ))
                           }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
   }
  </>
  )
}

export default ProductDetails
