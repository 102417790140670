import React from 'react'
import AppRouter from './router/AppRouter'
const App = () => {
  return (
    <>
    <AppRouter/>
    </>
  )
}

export default App
