import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from '../component/Header'
import Contact from '../pages/ContactP.jsx'
import Footer from '../component/Footer.jsx'
import About from '../pages/About.jsx'
import ProductDetails from '../pages/ProductDetails.jsx'
import Home from '../component/home/Home.jsx'
const AppRouter = () => {
  return (
   <>
    <BrowserRouter>
    <Header/>
    <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/:title' element={<ProductDetails/>}></Route>
    </Routes>
    <Footer/>
    </BrowserRouter>
   
   </>
  )
}

export default AppRouter
