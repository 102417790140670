import React from 'react'

const Service = () => {
  return (
    <>
     <div id="services" className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 ">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title-area ltn__section-title-2--- text-center">
          <h6 className="section-title" style={{fontSize:"30px"}}> <a href="https://www.investopedia.com/terms/r/realestateagent.asp" target="_blank" style={{textDecoration:"underline"}}>РИЭЛТОРЫ,</a> КОТОРЫМ МОЖНО ДОВЕРЯТЬ</h6>
        </div>
      </div>
    </div>
    <div className="row ltn__category-slider-active--- slick-arrow-1">
      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
        <div className="ltn__category-item ltn__category-item-5 text-center">
          <a>
            <span className="category-icon"><i className="flaticon-secure-shield" /></span>
            <span className="category-title">ПОЛНАЯ <br /> ПРОЗРАЧНОСТЬ</span>
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
        <div className="ltn__category-item ltn__category-item-5 text-center">
          <a>
            <span className="category-icon"><i className="flaticon-operator" /></span>
            <span className="category-title">БЕСПЛАТНЫЕ КОНСУЛЬТАЦИИ И ПОКАЗЫ</span>
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
        <div className="ltn__category-item ltn__category-item-5 text-center">
          <a>
            <span className="category-icon"><i className="flaticon-house" /></span>
            <span className="category-title">МЫ РАБОТАЕМ НА ВСЕЙ ТЕРРИТОРИИ ЭМИРАТОВ</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



    
    
    </>
  )
}

export default Service