import React, { useState } from "react";
import logo from "../assets/img/logo.png";
import dubai from "../assets/img/dubai.webp";
import { dubai_data } from "../data/dubai_data";
import slugify from "slugify";
const Header = () => {
  const [data] = useState(dubai_data);
  return (
    <>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:contact@my-group.ae">
                        <i className="icon-mail" /> contact@my-group.ae
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <div className="ltn__social-media">
                          <ul>
                            <li>
                              <a
                                href="https://www.instagram.com/myrealestate.az/?igsh=djZkNWl1aXJ1dGRn"
                                target="_blank"
                                title="Instagram"
                              >
                                <i className="fab fa-instagram" />
                                <span className="ms-2">My Real Estate</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="header-top-btn">
                          <a href="tel:+994512070902">+994 51 207 09 02</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo">
                    <a href="/">
                      <img
                        src={logo}
                        alt="Logo"
                        className="img-fluid"
                        style={{ maxWidth: "200px", width: "100%" }}
                      />
                    </a>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call" />
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4>
                        <a href="tel:+123456789">123-456-789-10</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        <li>
                          <a href="/">Домашняя страница</a>
                        </li>
                        <li>
                          <a href="/about">О нас</a>
                        </li>
                        <li className="menu-icon">
                          <a href="#">Страницы</a>
                          <ul className="mega-menu">
                            <li>
                              {data.slice(0, 6).map((item, i) => (
                                <li key={i}>
                                  <a
                                    href={`/${slugify(
                                      item.title
                                    ).toLowerCase()}`}
                                    onClick={() => window.location.reload()}
                                  >
                                    {item.title}
                                  </a>
                                </li>
                              ))}
                            </li>
                            <li>
                              {data.slice(6, 13).map((item, i) => (
                                <li key={i}>
                                  <a
                                    href={`/${slugify(
                                      item.title
                                    ).toLowerCase()}`}
                                    onClick={() => window.location.reload()}
                                  >
                                    {item.title}
                                  </a>
                                </li>
                              ))}
                            </li>
                            <li>
                              {data.slice(13, 18).map((item, i) => (
                                <li key={i}>
                                  <a
                                    href={`/${slugify(
                                      item.title
                                    ).toLowerCase()}`}
                                    onClick={() => window.location.reload()}
                                  >
                                    {item.title}
                                  </a>
                                </li>
                              ))}
                            </li>
                            <li>
                              <a href="shop.html">
                                <img src={dubai} alt="#" />
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/contact">Контакт</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col ltn__header-options ltn__header-options-2 mb-sm-20 mobileHamburger">
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <a href="/">
                <img src={logo} alt="Logo" className="w-50" />
              </a>
            </div>
            <button className="ltn__utilize-close">×</button>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <a href="/">Домашняя страница</a>
              </li>
              <li>
                <a href="/about">О нас</a>
              </li>
              <li>
                <span className="menu-expand"></span>
                <a href="#">Страницы</a>
                <ul className="sub-menu">
                  <li>
                    {data.slice(0, 6).map((item, i) => (
                      <li key={i}>
                        <a
                          href={`/${slugify(item.title).toLowerCase()}`}
                          onClick={() => window.location.reload()}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </li>
                  <li>
                    {data.slice(6, 13).map((item, i) => (
                      <li key={i}>
                        <a
                          href={`/${slugify(item.title).toLowerCase()}`}
                          onClick={() => window.location.reload()}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </li>
                  <li>
                    {data.slice(13, 18).map((item, i) => (
                      <li key={i}>
                        <a
                          href={`/${slugify(item.title).toLowerCase()}`}
                          onClick={() => window.location.reload()}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </li>
                </ul>
              </li>
              <li>
                <a href="/contact">Контакт</a>
              </li>
            </ul>
          </div>
          <div className="ltn__social-media-2">
            <ul className="d-flex justify-content-between">
              <li>
                <a
                  href="https://www.instagram.com/myrealestate.az/?igsh=djZkNWl1aXJ1dGRn"
                  target="_blank"
                  title="Instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ltn__utilize-overlay" style={{ display: "none" }}></div>
    </>
  );
};

export default Header;
